import React from "react";

import style from "./hbcu.module.css";
import { Col, Row } from "react-bootstrap";
import { Helmet } from 'react-helmet-async';

const HBCU = () => {
  return (
    <>
    <Helmet>
    <title>HBCU - I Stand With The Forgotten Women</title>
    <meta name="description" content="Helping HBCU students navigate unexpected pregnancies while pursuing their education with the support they need to succeed. " />
    <meta name="keywords" content="Impact on men,Unintended pregnancy,HBCU project,Academic challenges,Financial strain,Mental and emotional support,Pregnancy support system,Black women empowerment,Equal opportunity,Expanding program"/>
  </Helmet>
<div className={style.hbcuimageconatiner }>
  
    <div className="container">
      <div >
        <Row>
          <Col md={6} className={style.imagehbcu}>
          <img  src="/newgirl.png" alt="newgirl"></img>
          </Col>
          <Col md={6} className={style.hbcuhead}>
        
          <div className={`${style.mainhbcu} ${style.newmainhbcu}`}>
        <h1>The HBCU Project 
        </h1>
        <p>
        College is a transformative time in a young person's life—filled with growth, discovery, and the pursuit of their dreams. But for many students, an unintended pregnancy can drastically alter the course of their education and future. It can lead to financial strain and mental and emotional challenges, often forcing students to put their academic goals on hold.
       While some students may seek termination, many others prefer to continue their pregnancies but face overwhelming challenges in doing so. These students need a robust support system to ensure they can pursue their academic goals while preparing for motherhood. With the abortion rate for my community almost five times greater than any other community, I’ve chosen to start where the need is most pressing. I believe that black women should have an equal opportunity to carry their pregnancies to term. The end goal is to expand this program beyond HBCU campuses.


        </p>
        <p>
        Get ready for an amazing reveal. Something that’s never been attempted and never been needed more!
        </p>
      </div>
    
          </Col>
        </Row>

      </div>
    </div>
    </div>
    </>
  );
};

export default HBCU;
