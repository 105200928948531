import React from "react";
import style from "./aboutus.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Imagetxt  from "../../asserts/Imagetxt.png";

const Supportabout =()=> {
  const navigate = useNavigate();
  return (
    <section id="supportsection"> 
    <div className={style.supportmain}>
        
      
          <div className={style.supportcontainer}>
     
      <Row className="m-0">
   
          <Col md={5} >
          <a href="https://supportafterabortion.com/resources/unraveled-roots/" target="_blank"><img src={Imagetxt} alt="imagtext"></img></a>
         <a href="https://supportafterabortion.com/resources/unraveled-roots/" target="_blank">  <h2 >Unraveled Roots:</h2></a>
          </Col>
          <Col md={7} >
          
          <p>The more you explore this area, the more you’ll discover behind the painted smiles lies a reservoir of unwanted emotions and a longing for empathy. Being “pro-woman” means more than just standing with a woman when her choice is for abortion. Ending support there reveals a lack of genuine concern, and sensitivity. As well as an understanding of its emotional impact. It’s a hard case to make in defense of being “pro-woman” without acknowledging and a willingness to address the emotional repercussions experienced by those who face the challenge of turning the page. </p>
          <p>This is the time she needs someone to stand with her the most. </p>
          <a href="#">Stand with her by making a donation today.</a>
         
          </Col>
        
      </Row>
      </div>
  

  
    </div>
    </section>
  );
};

export default Supportabout;
