import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import style from "./home.module.css";
import axios from 'axios';
import { toast } from 'react-toastify';
import { baseUrl } from '../../utils/api';

const Newsletter = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError(""); // Clear error when user starts typing
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic email validation regex
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email) {
      setError("Email address is required.");
    } else if (!emailPattern.test(email)) {
      setError("Please enter a valid email address.");
    } else {
      setError("");
      try {
        const response = await axios.post(`${baseUrl}/auth/subscribe-email`, { email });
        console.log(response,"____________");
        
        if (response?.status === 200) {
          toast.success('Successfully subscribed');
          setEmail(""); // Clear the email input field
          return response?.data;
        }
      } catch (error) {
        if (error?.response?.data) {
          const errorMessage = error.response.data.message;
          toast.error(errorMessage);
        }
      }
    }
  };

  return (
    <div className={style.navmaindb}>
      <div className="container">
        <div className={style.newsdata}>
          <Row className={style.newshead}>
            <Col md={12} lg={12} xl={6} xs={12} className="p-0">
              <h3>
                Subscribe to our monthly newsletter and stay up to date with all
                news and events.
              </h3>
            </Col>

            <Col lg={8} xl={4} md={8} xs={8}>
              <div className={style.inputtype}>
                <input
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="Enter your email address"
                  className={style.inplace}
                />
                {error && <div className={style.error}>{error}</div>}
              </div>
            </Col>
            <Col lg={4} xl={2} md={4} xs={4}>
              <div className={style.databut}>
                <button onClick={handleSubmit}>Submit</button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
