import React from "react";
import style from "./aboutus.module.css";
import { Col, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import EmpoweringAbout from "./empowering";
import Championabout from "./champions";
import { Helmet } from 'react-helmet-async';

import Book from "./Book";
import Newsletter from "../home/newsletter";

import Supportabout from "./support";
import mike from "../../asserts/mike.png";
import { useEffect } from "react";

const AboutUs = () => {

  const location = useLocation();

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  return (
    <>
      <Helmet>
        <title>About Us - I Stand With The Forgotten Women</title>  
        <meta name="description" content="Abortion stems from fear, not empowerment. Offer women real support to nurture life and honor their core values. Stand with her today. " />
        <meta name="keywords" content="Fear and desperation,Empowerment support,Respect for life,Long-term suffering,Life-affirming alternatives,Pro-life crowdfunding,Justice and fairness,Emotional impact of abortion,Pro-woman support,Pastoral leadership"/>
      </Helmet>
      <div className={style.aboutimage}>
        <div className="container">
        
          <div className={style.aboutmainp}>
            <p>
              The decision to abort is not made from a place of empowerment,but
              from a place of fear,desperation.
            </p>
          
          </div>
        </div>
      </div>
      <EmpoweringAbout></EmpoweringAbout>
      <Championabout></Championabout>
 

      <div className={style.aboutus_row}>
        
        <section id="foundersection">
          <div className="container">
            <div className={style.aboutmaincol}>
              <Row className="m-0">
                {/* Image Column */}
                <Col className="text-center text-md-start p-0" lg={4} md={12}>
                  <div className={style.aboutusimagecontainer}>
                    <img src={mike} alt="story" />
                  </div>
                </Col>
                {/* Text Column */}
                <Col className="m-auto p-0" lg={8} md={12}>
                  <div className={style.abouttextcontainer}>
                    <div className={style.aboutus_heading}>About The Founder</div>
                    {/* <div className={` ${style.aboutus_text}`}> */}

                    {/* </div> */}
                    <div className={style.abouthead}>
                   <p>
                   An entrepreneur at heart. A serial entrepreneur. I’ve spent my entire adult life in various business ventures. For the last ten years, I’ve longed for an opportunity to use my many years of hard-earned business knowledge to create an enterprise that would have a positive impact on the lives of others. I believe this pro-life crowdfunding platform will be the instrument that accomplishes that.</p>
                   <p>
                   I’m often asked what motivated me to take on this issue. I can sum it up in one word—injustice. Justice is not just the principal cornerstone of civilized society; it is also the guiding principle for treating others fairly. At its core, it entails treating everyone equally regardless of their background, social status, or personal beliefs. Simply put, justice demands that what you do for one , you do for the other.
                   </p>
                   <p>
                   When I objectively assess the overwhelming support given by the pro-choice community to the abortion-mined female facing an unplanned pregnancy versus the total lack of support offered for the female who’s desperately trying to stave off an abortion, the unfairness of it jumps off the page.
                   </p>

                   <p>
                   For a movement (pro-choice) built on freedom of choice, I think anyone would agree that it’s unjust to devote all their energies to one group of women facing unplanned pregnancy and none to the other. It’s clear to me that the woman whose conscience dictates that she makes a different choice is being ignored. I want to help change that.
                   </p>
                      
                      <h4>Your donation makes a difference</h4>
                      <div className={style.aboutheadbutton}>
                        <a href="/search-center">
                          {" "}
                          <button>Stand With Us</button>
                        </a>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </section>
        

      </div>
     
 
      <Supportabout></Supportabout>
       
      <div className={style.aboutrow}>
        <div className={style.imagecontainer}>
          <img className="w-100" src="/empower.png" alt="empower"></img>
        </div>
        <div className={style.abouttextheadp}>
      
          <h1>
            Pro-choice should mean support for women regardless of their choice.
            Support shouldn’t be given only when the ‘choice’ is to end life.
          </h1>
        </div>
      </div>


      <div className={style.helpabout}>
        <h1>24 Hour Hotline:</h1>
        <h1>(800) 712-HELP (4357)</h1>
      </div>

      <Book></Book>
      <Newsletter></Newsletter>
    </>
  );
};

export default AboutUs;
