import React from "react";
import style from "./aboutus.module.css";



const EmpoweringAbout = () => {
  // const navigate = useNavigate();
  return (
    <div className={style.mainabout}>


      <div className="container">
        <h1>Abortion is not an act of empowerment.
          Rather, it is it an act of weakness driven by fear and desperation. We empower them when we provide  meaningful support that aligns with their core values.</h1>

        <h1 className="mt-3">Being nurturers by nature, abortion forces an attempt to suppress their  instincts to nurture and protect life.</h1>
     
      </div>

    </div>
  );
};

export default EmpoweringAbout;
