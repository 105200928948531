import React from "react";
import style from "./donation.module.css";
import { Col, Row } from "react-bootstrap";
import { getToken } from "../../utils/api";
import { jwtDecode } from "jwt-decode";
import { Helmet } from "react-helmet-async";


const DonationSucess = () => {
  let email;
  const token=getToken();
  console.log("token",token);
  if(token){
   console.log("Entertoken")
   const decoded = jwtDecode(token);
   console.log("decoded",decoded)
    email=  decoded?.email;


  }
  else{
    email=localStorage.getItem("email")
  }
  return (
    <>
     <Helmet>
    <title>Success - I Stand With Forgotten Women</title>
    <meta name="description" content="We appreciate your generous donation! Your support is vital in helping forgotten women. If you have questions, feel free to reach out. Together, we can take action." />
    <meta name="keywords" content="Thank you,Standing with us,Donation impact,Make a difference,Support women,Forgotten women,Action now,Giving back,Reach out,Community support"/>
  </Helmet>
      <div className={style.donationsucesscontainer}>
        <div className="container">
        {/* <img className="w-100" src="/Still.svg" alt="banner" /> */}
        <div className={style.donationcontainertext}>
          <h1>Thank you for Standing with Us</h1>
          {/* <h2>Mike Goss</h2> */}
          <p>No one wants to be forgotten</p>
        </div>
        </div>
      </div>

      <div className={style.donationthankcontainer}>
        <div className="container">
            {/* <Row className=" align-items-center">
                <Col md={12} lg={12} xl={6}> */}
        <div className={style.thankcontainertextp}>
        <p>
        Thank you so much for giving. Your donation is helping to make a difference. If you have any questions, please don’t hesitate to reach out.
          </p>

                {/* <p>
            Thank you so much for giving. We’ve sent a receipt for your donation
            to {email} If you have any questions, please
            don’t hesitate to reach out.
          </p> */}
          </div>
                {/* </Col>
                <Col md={12} lg={12} xl={6}>
                <div className={style.thankcontainertext}>
                <h3>We can Take <br></br>action now to<br></br> Support Forgotten<br></br> Women.</h3>
                </div>
                </Col>
            </Row> */}
         
        
     
        </div>
      </div>
    </>
  );
};

export default DonationSucess;
