import React from 'react'
import { Route, Routes } from 'react-router-dom'
import LoginRoutes from './LoginRoutes'
import MainRoutes from './MainRoutes'
import ScrollToTop from '../components/scrolltoTop/ScrollToTop'
import useGetUser from '../customHook/useGetUser'
import BackToTopButton from '../pages/backtotop/backbutton'

const AllRoutes = () => {
  useGetUser()
  return (
    <>
    <ScrollToTop />
    <BackToTopButton></BackToTopButton>
      <Routes>
        <Route path="/*" element={<LoginRoutes />} />
        <Route path="/dashboard/*" element={<MainRoutes />} />
      </Routes>
    </>
  )
}

export default AllRoutes