import { Formik } from "formik";
import React, { useState } from "react";
import { Col, Form, Row, Button, Spinner } from "react-bootstrap";
import style from "../auth/auth.module.css";
import * as Yup from "yup";
import { sendMessage } from "../../apis/authApis";
import { useDispatch } from "react-redux";
import { Helmet } from 'react-helmet-async';
const ContactUs = () => {
  const dispatch=useDispatch()
  const [disableButton, setDisableButton] = useState(false);

  return (
    <>
    <Helmet>
    <title>Contact-us - I Stand With The Forgotten Women</title>
    <meta name="description" content="Have questions? Reach out to us! Fill out the form with your name, email, and message, and we’ll get back to you as soon as possible." />
    <meta name="keywords" content="Contact us,Help,Questions,Name,Email,Message,Support,Inquiry,Assistance,Customer service"/>
  </Helmet>
    <div>
        <div>
        <img className="w-100" src="/contactusbanner.png" alt="banner" />
      </div>
      <Row className="m-0 p-0">
        <Col md={12} style={{ backgroundColor: "rgb(250, 250, 251)" }}>
            <div className="d-flex justify-content-center "
           
            >
              <div className="m-auto" >
                <h3 className="text-center mt-5 mb-4 ">
                We’re here to help and answer<br/>
                 any questions you might have.
                </h3>
                <Formik
                  initialValues={{
                    name: "",
                    email: "",
                    message: "",
                  }}
                  validationSchema={Yup.object().shape({
                    name: Yup.string()
                      .max(255)
                      .required("Name is required"),
                    message: Yup.string()
                      .required("Message is required"),
                    email: Yup.string()
                      .email("Must be a valid email")
                      .max(255)
                      .required("Email is required"),
                  })}
                  onSubmit={async (
                    values,
                    { setErrors, setStatus, setSubmitting,resetForm }
                  ) => {
                    try {
                      setDisableButton(true);
                      setStatus({ success: false });
                      setSubmitting(false);
                      dispatch(sendMessage(values)).then(() => {
                        setDisableButton(false);
                        resetForm()
                      });
                    } catch (err) {
                      setStatus({ success: false });
                      setErrors({ submit: err.message });
                      setSubmitting(false);
                      setDisableButton(false);
                    }
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Form.Group
                        className="mb-4"
                        controlId="formBasicName"
                      >
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          className={
                            touched.name && errors.name
                              ? "is-invalid"
                              : ""
                          }
                          value={values.name}
                          name="name"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(touched.name && errors.name)}
                        />
                        {touched.name && errors.name && (
                          <Form.Text className=" text-danger">
                            {errors.name}
                          </Form.Text>
                        )}
                      </Form.Group>
                     
                      <Form.Group className="mb-4" controlId="formBasicEmail">
                        <Form.Label>Email </Form.Label>
                        <Form.Control
                          className={
                            touched.email && errors.email ? "is-invalid" : ""
                          }
                          type="email"
                          value={values.email}
                          name="email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(touched.email && errors.email)}
                        />
                        {touched.email && errors.email && (
                          <Form.Text className=" text-danger">
                            {errors.email}
                          </Form.Text>
                        )}
                      </Form.Group>
                      <Form.Group
                        className="mb-4"
                        controlId="formBasicmessage"
                      >
                        <Form.Label>Message</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={4}
                          className={
                            touched.message && errors.message
                              ? "is-invalid"
                              : ""
                          }
                          value={values.message}
                          name="message"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(touched.message && errors.message)}
                        />
                        {touched.message && errors.message && (
                          <Form.Text className=" text-danger">
                            {errors.message}
                          </Form.Text>
                        )}
                      </Form.Group>
                      <Button
                        className="w-100 authBlubtn"
                        variant="primary"
                        type="submit"
                        disabled={disableButton}
                      >
                        Send Message
                        {disableButton && (
                      <Spinner
                        className="ms-3"
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                      </Button>
                    </Form>
                  )}
                </Formik>
                <div className="text-center mt-3 mb-4"></div>
              </div>
            </div>
        </Col>
 
      </Row>
    </div>
    </>
  );
};

export default ContactUs;
