import React from "react";
import style from "./aboutus.module.css";
import { Helmet } from "react-helmet-async";

const Churchesabout = () => {

  return (
    <>
   <Helmet>
    <title>Church -I Stand With Forgotten Women</title>
    <meta name="description" content="Discover how churches and pregnancy resource centers form a powerful partnership, offering holistic care, support, and hope to individuals in need through shared values. " />
    <meta name="keywords" content="Church support,Pregnancy resource centers,Unplanned pregnancies,Spiritual guidance,Compassionate care,Sanctity of life,Community of faith,Holistic support,Promoting dignity,Collaborative partnership"/>
  </Helmet>
    <div className={style.backcolor}>
        <div className={style.churchestext}>
   
        <div  className="container">
        <div className={style.churchtextp}>
        <h1>Our Greatest Need: Churches</h1>
         <p>The relationship between the church and pregnancy resource centers can be likened to a marriage ordained in heaven, where each entity complements and supports the other in fulfilling a shared mission. Pregnancy resource centers often serve as vital extensions of the church’s ministry, providing practical support, resources, and compassionate care to individuals facing unplanned pregnancies and related challenges.</p>

            <p>Likewise, the church offers spiritual guidance, moral support, and a community of faith to individuals served by pregnancy resource centers. Together, they form a powerful partnership grounded in love, compassion, and a shared commitment to upholding the sanctity of human life and supporting individuals in need. Just as a marriage thrives on mutual respect, cooperation, and shared values, so too does the relationship between the church and pregnancy resource centers flourish when grounded in a shared vision of promoting life, dignity, and hope for all. Through their collaborative efforts, they can offer holistic support and care to individuals and families, embodying the transformative power of love and grace.</p>
            </div>
        </div>
        </div>
        </div>
        </>
  );
};

export default Churchesabout;
