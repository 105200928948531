import React from "react";
import style from "./book.module.css";


const Modeled = () => {

  return (
    <div className={style.modeled_after_divine}>


      <div >
        {/* <Row className="m-0"> */}
          {/* <Col className="text-center text-md-start d-flex" lg={6} md={12} sm={12} xs={12}>
           
          </Col> */}
          {/* <Col className=" p-0" lg={6} md={12} sm={12} xs={12}> */}
            <div className={style.content_modeled_wrapper}>
              <div className="container">
              <div className={style.wisdom_heading} >God's 'Blueprint' for Addressing Unplanned Pregnancies</div>
              <div className={`mb-0 ${style.book_text}`}>
                {/* <h5 className={style.courts_heading_text}>The courts can make abortion illegal, but only God, through his church, can make it unnecessary</h5>
                <p className={style.bymiketitle}>by Mike Goss</p> */}
                <p className={style.wisdom_text}>This ‘Blueprint’ draws inspiration from the most well-known unplanned pregnancy in history—the birth of Christ. Modeled After Another Unplanned Pregnancy (from a human perspective)  as God provided a way to prevent what could have been a crisis, this resource provides a roadmap for the church to avoid and address unplanned pregnancies with grace and wisdom. It’s time to expand our mission from being Pro-Life to embracing a Pro-Abundant Life approach.</p>

                {/* <p className={`${style.wisdom_text} ${style.wisdom_bold}`}>Abortion: A forced attempt to deny a mother’s instinctual nature to nurture and protect life without regard to the right to life for the child she’s carrying and the psychological repercussions to her.</p> */}
              </div>
              </div>
            </div>
          {/* </Col> */}

        {/* </Row> */}
      </div>
    </div>

  );
};

export default Modeled;
