import React from "react";

import style from "./home.module.css";

const Neighbour = () => {
  return (
    <div className={style.mainrow}>
    <div className={style.neighbourimagecontainer}>
      <img className="w-100" src="/imagegroup.jpg" alt="imagegroup"></img>
    </div>
    <div className={style.neighbourcontainer}>
    <div className={style.neighbourtext}>
      <h1>
      Love Thy Neighbor 
    </h1>
      <p className="m-0" >
      Once you grasp the threat that terminating life poses to your core values it awakens the moral will to take action, overriding any political or social pressures seeking to diminish the sanctity of human life and the mental and emotional health of your fellow sisters and brothers.
      </p>
    </div>
    </div>
  </div>
   
  );
};

export default Neighbour;
