import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, InputGroup, Spinner } from "react-bootstrap";
import authstyle from "../auth/auth.module.css";
import * as Yup from "yup";
import { Formik } from "formik";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { updateUserData, updatePassword } from "../../apis/userApis";
import { getSubscriptions,cancelSubscriptions } from "../../apis/paymentApis";
import { toast } from "react-toastify";
import useGetUser from "../../customHook/useGetUser";
import { Helmet } from "react-helmet-async";

const Profile = () => {
  const dispatch = useDispatch();
  useGetUser()
  const { user } = useSelector((state) => state.user);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmassword, setShowConfirmPassword] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const [disableButton, setDisableButton] = useState(true);
  const [loading, setLoading] = useState(false);
  const [subscriptionDetails,setSubscriptionsDetails]=useState([])

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const fetchAllSubscription=()=>{
    dispatch(getSubscriptions({id:user?.id?user?.id:user?._id})).then(res=>{
      if(res?.subscriptions){
        setSubscriptionsDetails(res?.subscriptions)
      }
    })
  }

  useEffect(() => {
    setInitialValues(user);
    if(user){
     fetchAllSubscription()
    }
  }, [user]);

  const handleCancelSubs=(value)=>{
   dispatch(cancelSubscriptions({id:value?.subscriptionId})).then(res=>{
    if(res){
      fetchAllSubscription()
      toast.success("Subscription Canceld")
    }
   })

  }


  return (
    <>
     <Helmet>
        <title>Profile - I Stand With The Forgotten Women</title>
        <meta name="description" content="Update your profile details, change your password, or manage your $1006 monthly subscription. Make an impact and easily adjust your contribution anytime."/>
        <meta name="keywords" content="Profile,First name,Last name,Email,Change password,Current password,New password,Subscription,Monthly contribution,Cancel subscription"/>
      </Helmet>
    <div
      className="d-flex justify-content-center"
      style={{ minHeight: "100vh" }}
    >
      <Row className="m-0 p-0 w-100">
        <Col xs={12} sm={8} md={8} lg={8} className="mx-auto p-0">
          <div className={`${authstyle.authBox}`}>
            <Row className="p-0 mb-5">
              <Col md={6}>
                <div className={authstyle.authBox}>
                  <h3 className="text-center mb-4 "> Profile</h3>
                  <Formik
                    initialValues={{
                      firstName: initialValues?.firstName
                        ? initialValues?.firstName
                        : "",
                      lastName: initialValues?.lastName
                        ? initialValues?.lastName
                        : "",
                      email: initialValues?.email ? initialValues?.email : "",
                    }}
                    validationSchema={Yup.object().shape({
                      firstName: Yup.string()
                        .max(255)
                        .required("FirstName is required"),
                      lastName: Yup.string()
                        .max(255)
                        .required("LastName is required"),
                      //   password: Yup.string()
                      //     .max(255)
                      //     .required("Password is required"),
                    })}
                    enableReinitialize
                    onSubmit={async (
                      values,
                      { setErrors, setStatus, setSubmitting }
                    ) => {
                      try {
                        setLoading(true);
                        setStatus({ success: false });
                        setSubmitting(false);
                        dispatch(
                          updateUserData({
                            values: values,
                            id: user.id ? user.id : user._id,
                          })
                        ).then(() => {
                          setLoading(false);
                        });
                      } catch (err) {
                        setStatus({ success: false });
                        setErrors({ submit: err.message });
                        setSubmitting(false);
                        setLoading(false);
                      }
                    }}
                  >
                    {({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      touched,
                      values,
                    }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <Form.Group
                          className="mb-4"
                          controlId="formBasicFirstName"
                        >
                          <Form.Label>First Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="FirstName"
                            className={
                              touched.firstName && errors.firstName
                                ? "is-invalid"
                                : ""
                            }
                            value={values.firstName}
                            name="firstName"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              setDisableButton(false);
                            }}
                            error={Boolean(
                              touched.firstName && errors.firstName
                            )}
                          />
                          {touched.firstName && errors.firstName && (
                            <Form.Text className=" text-danger">
                              {errors.firstName}
                            </Form.Text>
                          )}
                        </Form.Group>
                        <Form.Group
                          className="mb-4"
                          controlId="formBasicLastName"
                        >
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="LastName"
                            className={
                              touched.lastName && errors.lastName
                                ? "is-invalid"
                                : ""
                            }
                            value={values.lastName}
                            name="lastName"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              setDisableButton(false);
                            }}
                            error={Boolean(touched.lastName && errors.lastName)}
                          />
                          {touched.lastName && errors.lastName && (
                            <Form.Text className=" text-danger">
                              {errors.lastName}
                            </Form.Text>
                          )}
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="formBasicEmail">
                          <Form.Label>Email </Form.Label>
                          <Form.Control
                            type="email"
                            value={values.email}
                            name="email"
                            disabled={true}
                          />
                        </Form.Group>
                        <Button
                          className="w-100 authBlubtn"
                          variant="primary"
                          type="submit"
                          disabled={disableButton}
                        >
                          Update Profile
                          {loading && (
                            <Spinner
                              className="ms-3"
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Col>
              <Col md={6}>
                <div className={authstyle.authBox}>
                  <h3 className="text-center mb-4 ">Change Password</h3>
                  <Formik
                    initialValues={{
                      currentPassword: "",
                      newPassword: "",
                      confirmPassword: "",
                    }}
                    validationSchema={Yup.object().shape({
                      currentPassword: Yup.string().required(
                        "Current password is required"
                      ),
                      newPassword: Yup.string()
                        .required("New password is required")
                        .min(
                          8,
                          "New password must be at least 8 characters long"
                        ),
                      //   .matches(/[a-z]/, 'New password must contain at least one lowercase letter')
                      //   .matches(/[A-Z]/, 'New password must contain at least one uppercase letter')
                      //   .matches(/\d/, 'New password must contain at least one number')
                      //   .matches(/[!@#$%^&*()_+\-=[\]{};':\\|,.<>/?]/, 'New password must contain at least one special character'),
                      confirmPassword: Yup.string()
                        .required("Confirm password is required")
                        .oneOf(
                          [Yup.ref("newPassword"), null],
                          "Passwords must match"
                        ),
                    })}
                    onSubmit={async (
                      values,
                      { setErrors, setStatus, setSubmitting, resetForm }
                    ) => {
                      try {
                        setStatus({ success: false });
                        setSubmitting(false);
                        dispatch(updatePassword(values)).then((res) => {
                          if (res) resetForm();
                        });
                      } catch (err) {
                        setStatus({ success: false });
                        setErrors({ submit: err.message });
                        setSubmitting(false);
                      }
                    }}
                  >
                    {({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      touched,
                      values,
                    }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <Form.Group
                          className="mb-4"
                          controlId="formBasicFirstName"
                        >
                          <Form.Label>Current Password</Form.Label>
                          <InputGroup>
                            <Form.Control
                              className={
                                touched.currentPassword &&
                                errors.currentPassword
                                  ? "is-invalid"
                                  : ""
                              }
                              type={showPassword ? "text" : "password"}
                              placeholder="********"
                              value={values.currentPassword}
                              name="currentPassword"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              error={Boolean(
                                touched.currentPassword &&
                                  errors.currentPassword
                              )}
                            />
                            <InputGroup.Text
                              style={{ cursor: "pointer" }}
                              onClick={togglePasswordVisibility}
                            >
                              {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </InputGroup.Text>
                          </InputGroup>
                          {touched.currentPassword &&
                            errors.currentPassword && (
                              <Form.Text className=" text-danger">
                                {errors.currentPassword}
                              </Form.Text>
                            )}
                        </Form.Group>
                        <Form.Group
                          className="mb-4"
                          controlId="formBasicLastName"
                        >
                          <Form.Label>New Password</Form.Label>
                          <InputGroup>
                            <Form.Control
                              className={
                                touched.newPassword && errors.newPassword
                                  ? "is-invalid"
                                  : ""
                              }
                              type={showNewPassword ? "text" : "password"}
                              placeholder="********"
                              value={values.newPassword}
                              name="newPassword"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              error={Boolean(
                                touched.newPassword && errors.newPassword
                              )}
                            />
                            <InputGroup.Text
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                setShowNewPassword(!showNewPassword)
                              }
                            >
                              {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                            </InputGroup.Text>
                          </InputGroup>
                          {touched.newPassword && errors.newPassword && (
                            <Form.Text className=" text-danger">
                              {errors.newPassword}
                            </Form.Text>
                          )}
                        </Form.Group>

                        <Form.Group
                          className="mb-4"
                          controlId="formBasicPassword"
                        >
                          <Form.Label>Confirm Password</Form.Label>
                          <InputGroup>
                            <Form.Control
                              className={
                                touched.confirmPassword &&
                                errors.confirmPassword
                                  ? "is-invalid"
                                  : ""
                              }
                              type={showConfirmassword ? "text" : "password"}
                              placeholder="********"
                              value={values.confirmPassword}
                              name="confirmPassword"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              error={Boolean(
                                touched.confirmPassword &&
                                  errors.confirmPassword
                              )}
                            />
                            <InputGroup.Text
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                setShowConfirmPassword(!showConfirmassword)
                              }
                            >
                              {showConfirmassword ? <FaEyeSlash /> : <FaEye />}
                            </InputGroup.Text>
                          </InputGroup>

                          {touched.confirmPassword &&
                            errors.confirmPassword && (
                              <Form.Text className=" text-danger">
                                {errors.confirmPassword}
                              </Form.Text>
                            )}
                        </Form.Group>

                        <Button
                          className="w-100 authBlubtn"
                          variant="primary"
                          type="submit"
                        >
                          Update Password
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Col>
            </Row>
            {subscriptionDetails.length>0 && 
            <div className={`mb-5 mt-0 ${authstyle.authBox}`}>
              <h4 className="mb-3 "> Subscription</h4>
              {subscriptionDetails?.map((value,index)=>(
                  <>
                <Row className="p-0 mb-3" key={index}>
                  <Col md={6} className="m-auto">
                    <h5 className=" mt-3">
                      {/* You have taken monthly Subscription of 10$ for Demo Center. */}
                      Your {`${value?.interval}ly`} contribution  of ${value?.amount}  makes a real impact.
                      You can cancel your subscription at any time by clicking the
                      Cancel Subscription button .
                    </h5>
                  </Col>
                  <Col md={6}>
                    <div className={`mt-0 ${authstyle.authBox}`}>
                      <h5 className="mb-3 "> {value.centerName}</h5>
                      <Button className="w-100" variant="danger" type="button" onClick={()=>handleCancelSubs(value)}>
                        Cancel Subscription
                      </Button>
                    </div>
                  </Col>
                </Row>
                <hr/>
                  </>
              ))
              
              }
        
            </div>
        }
          </div>
        </Col>
      </Row>
    </div>
    </>
  );
};

export default Profile;
