// import logo from './logo.svg';
import './global.css';
import { BrowserRouter } from 'react-router-dom';
import AllRoutes from './routes';

function App() {


  return (
    <BrowserRouter>
    <AllRoutes />

    </BrowserRouter>
   
  );
}

export default App;
