import React, { useState } from "react";

import style from "./home.module.css";

const Mission = () => {

  const [currentIndex, setCurrentIndex] = useState(0);

  const content = [
    {
      imgSrc: "/women1.png",
      
      sections: [
        {
      headings: "Our vision",
      paragraphs: 
        "A society where our focus has shifted from the politics of abortion to one where we acknowledge that women facing unplanned pregnancies must be provided with solutions that are more in alignment with their humanity to nurture and safeguard life.",
        headingClass: style.headingone,
        paragraphClass: style.paragraphone,
      
    }
  ],
  containerClass: style.containerOne,
    },
    {
      imgSrc: "/womnew.png",
      sections:[
        {
      headings: "Who Are We",
      
      paragraphs: 
        "We are a nonprofit (501c3) organization created to address the inequitable distribution of support given to the abortion-determined female versus that given to the abortion-vulnerable female who prefers an alternative.",

        headingClass: style.headingtwo,
        paragraphClass: style.paragraphtwo,

       
       
      
    },
    
 
      {
        headings: "Our Mission",
        paragraphs: 
          " To provide a central platform for those who desire to support organizations that affirm life anywhere across the globe.",

          headingClass: style.headingthree,
          paragraphClass: style.paragraphthree,
      }
  
    ],
    containerClass: style.containerTwo,
    },

    {
      imgSrc: "/women3.png",
      sections:[
        {
      headings: "Create Awareness",
      // paragraphs: 
      //   "Abortion is an extreme action that negatively affects many women, including some men.",
      //   headingClass: style.headingfour,
      //   paragraphClass: style.paragraphfour,
       
    },
    // {
    //   paragraphs: "The need to support women and couples facing complicated pregnancy decisions so they can explore all their options.",
    //   headingClass: style.headingfour,
    //   paragraphClass: style.paragraphfour,
    // },
    // {
    //   paragraphs: "The tremendous need to provide counseling support for those struggling with the emotional consequences of an abortion.",
    //   headingClass: style.headingfour,
    //   paragraphClass: style.paragraphfour,
    // },
    {
      paragraphs: "Terminating a life is an extreme action that has adverse outcomes for many women and men. The need to support organizations like pregnancy resource centers, maternity homes, and adoption agencies who provide without cost compassionate help and hope to those facing complicated pregnancy decisions.",
      headingClass: style.headingfour,
      paragraphClass: style.paragraphfour,
    },
    {
      paragraphs: "Our tremendous need to provide post-abortive counseling for the many women and men who struggle with the emotional consequences.",
      headingClass: style.headingfour,
      paragraphClass: style.paragraphfour,
    },
 
    {
      paragraphs: "Abortion cannot be the only tool in our toolbox that we reach  for to address unplanned pregnancies." ,
      headingClass: style.headingfour,
      paragraphClass: style.paragraphfour,
    }
    ],
    containerClass: style.containerThree,
    }
  ];

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === content.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? content.length - 1 : prevIndex - 1
    );
  };

  
  return (
    <div className={style.imcontainer}>
        <img className="w-100" src={content[currentIndex].imgSrc} alt="banner" />
        <div className={style.missionmain}>
        <div className={`${style.imtextContent} ${content[currentIndex].containerClass}`}>
        {content[currentIndex].sections.map((section, index) => (
          <div key={index} >
            <h1 className={section.headingClass}>{section.headings}</h1>
            <p className={section.paragraphClass}>{section.paragraphs}</p>
          </div>
        ))}
        <div className={style.misionbutton}>
          <a href="/donate-to-us/">
        <button >Stand With Us</button></a>
        </div>
        </div>
        </div>
        <div className={style.navigationButtons}>
        <img
          src="/Polygon3.png"
          alt="Previous"
          className={style.previousButton}
          onClick={handlePrevious}
        />
        <img
          src="/Polygon2.png"
          alt="Next"
          className={style.nextButton}
          onClick={handleNext}
        />
      </div>
       
      </div>
      
  );
};

export default Mission;
