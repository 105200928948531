import React from "react";
import style from "./book.module.css";

const Matters = () => {

  return (
    <div className={style.matters_wrapper}>
      <div className="container">
        {/* <h3 className={style.matters_h3}>Why This Matters</h3>
        <p className={style.matters_p}>Our Pro-Life mission, as God-honoring as it has been, has struggled to break the intergenerational cycle of unplanned pregnancies that lead to the risk of abortion. In John 10:10, Christ clarifies that He’s not just pro-life but pro-abundant life. This resource shows how we can prevent the needless deaths of millions of infants and the psychological scarring that follows.<span>The good news? We still can.</span> </p> */}
        <h3 className={style.matters_h3}>This 'Blueprint' is the only solution that can break the chain of intergenerational abortions that lead to unplanned pregnancies.</h3>
        <p className={style.matters_p}>Get your copy of<span> The Blueprint to ending abortion in the church </span>today and begin equipping your church with the tools it needs to navigate the complexities of abortion with biblical integrity, compassion, and practical insight.
          Your members will thank you for it.</p>

        <div className={`text-center ${style.book_done_button}`}>
          <a href="https://9eb34e-77.myshopify.com/collections/all" target="_blank">
          <button className="mt-0">Order Now</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Matters;
