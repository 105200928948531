

import React, { useEffect, useState } from "react";
import style from "./donation.module.css";
import {
 
  
  Col,
  
  
  Row,

} from "react-bootstrap";

import { getToken } from "../../utils/api";
import {
AdminPayment,
 createAdminSubscription,
  
} from "../../apis/paymentApis";
import { useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";

const Information = () => {
  
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [id, setId] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(()=>{
    const token=getToken();
    console.log("token",token);
    if(token){
     console.log("Entertoken")
     const decoded = jwtDecode(token);
     console.log("decoded",decoded)
       setId(decoded?.sub);
        setEmail(decoded?.email);
       setIsLoggedIn(true);
  
    }
  },[])
  
  if(!isLoggedIn){
    localStorage.setItem("email",email)
  }
  const amount = localStorage.getItem("amount");
    
  const frequency = localStorage.getItem("frequency");
  const handleCheckout = () => {
    console.log("Enter")
  
    if (!isNaN(amount) && Number(amount) >= 0) {
      console.log("enter")
      if (frequency === "1") {
        console.log("ente rinto")
        dispatch(
          AdminPayment({
            amount: amount,
            email:email,
            id:id
          })
        ).then((res) => {
          if (res?.data) {
            window.location.href = res?.data;
            // window.open(res?.data, "_blank");
          }
        });
      } else {
        dispatch(
          createAdminSubscription({
            amount: amount,
            interval:"month",
            email:email,
            id:id
            
          })
        ).then((res) => {
          if (res?.url) {
            window.location.href = res?.url;
            // window.open(res?.url, "_blank");
          }
        });
      }
    } else {
      toast.error("Invalid amount. Please enter a valid number.");
      return;
    }
  };
return (
  <>
    <Helmet>
    <title>Information - I Stand With Forgotten Women</title>
    <meta name="description" content="Complete your recurring donation and stand with those whose conscience leads them to make different choices. Your contribution makes a lasting impact." />
    <meta name="keywords" content="Email address,Confirm donation,Recurring donation,Process payment,Support donation,Monthly contribution,Donation confirmation,Payment processing,Conscience support,Donation button"/>
  </Helmet>

    <div className={style.inforcontainer}>
      <div className="container">
        <div className={style.paycol}>
          <Row>
          <Col md={12} xl={7} lg={12}>
           
                <div className={style.infoform}>
                  <div className={style.paymentHead}>Your Information</div>
                  <div className={style.donationpayment}>

    

                  <div className={style.informationnputmaincontainer} >
                    <label className={style.labelesign}
                    >
                      <div className={style.informationinnerlabel}>
                      <div className={style.informationscreeninput}>
                      <span>EMAILADDRESS</span>
                      <div>
                        <input
                          type="email"
                         placeholder="Email address"
                         value={email}
                         onChange={(e) => setEmail(e.target.value)}
                         className={style.informationinput}
                         disabled={isLoggedIn} // Disable if logged in
                         
                       />
                        </div>
                      
                        </div>
                      
                     
                      </div>
                    </label>
                  </div>

            
                    <div className={style.informationdonation} >
        
                  <div className={style.informationtextp}>
                  <p>Click the button to confirm your donation of ${amount}, {frequency == '1' ? " one-time" : " recurring month"}.</p>
      </div>
     

                  </div>
                
                  <div className={style.informationbuttoncontainer}>
                    <div className={style.inforationback}>
                    <a href="/donate-to-us/">Go back</a>
                    </div>
                    <div className={style.informationscreenbutton}>
                    <button onClick={handleCheckout}>Process Payment</button>
                    </div>
                  </div>
                 
                  </div>
                
                </div>
         

            </Col>

            <Col md={12} xl={5} lg={5} xs={12}>
              <div className={style.informationthank}>
                <h1>
                Supporting those whose conscience dictates a different choice 

                </h1>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
    </>
  );
};

export default Information;