import axios from 'axios';
import { toast } from 'react-toastify';
import { setUserDetails } from '../redux/reducers/user';
// import { baseUrl,getHeader } from 'utils/api';
import { baseUrl,getHeader } from '../utils/api';

export const createResume =({value,id})=> async (dispatch) => {
    try {
        const header=  await getHeader()
        const formData = new FormData();
        formData.append('name', value.name);
        formData.append('email', value.email);
        formData.append('phone',value.phone);
        formData.append('resume', value.file);
        formData.append('captchaToken',value.captchaValue)
      

      const response = await axios.post(`${baseUrl}/hiring/createresume`,formData
      ,{
        headers:header });
      if(response?.status===200 ||response?.status===201){
        console.log("Rewsponse",response);
        toast.success("Data saved successfully")
       
        return response?.data;
      }
    } catch (error) {
      console.error("Error:",error);
      if(error?.response?.data){
       const errorMessage=error.response.data.message
       console.log("update password error:",errorMessage)
       toast.error(errorMessage)
      }
    }
  };

// export const createResume = (formData) => async (dispatch) => {
//     try {
//       const response = await axios.post(
//         `${baseUrl}/hiring/createresume`, 
        
//         formData,  // formData should be passed as the second argument
//         {
//           headers: {
//             'Content-Type': 'multipart/form-data',
//           },
//         }
//       );
//       console.log("Respose",response)
  
//       if (response?.status === 200 || response?.status === 201) {
//         toast.success("Data created successfully");
//       }
  
//       return response;
//     } catch (error) {
//       console.error("Error:", error);
//       if (error?.response?.data) {
//         const errorMessage = error.response.data.message;
//         console.log("Error:", errorMessage);
//       }
//     }
//   };
  