import axios from 'axios';
import { toast } from 'react-toastify';
import { setUserDetails } from '../redux/reducers/user';
import { baseUrl } from '../utils/api';
import { Country,State, } from 'country-state-city';
import { getUserDetails } from './userApis';

export const registerUser =(data,navigate)=> async (dispatch) => {
  try {
    const response = await axios.post(`${baseUrl}/auth/register`, {
      firstName:data?.firstName,
      lastName:data.lastName,
      email: data?.email,
      password: data?.password,
      role:data?.role,
    });
    if(response?.status===201){
      dispatch(setUserDetails(response?.data?.user))
      localStorage.setItem("token",JSON.stringify(response?.data?.tokens?.access)) 
     localStorage.setItem("refreshToken", JSON.stringify(response?.data?.tokens?.refresh))
     navigate("/")
      return response?.data?.user;
    }
  } catch (error) {
    console.error("Error:",error);
    if(error?.response?.data){
     const errorMessage=error.response.data.message
     console.log("Registration error:",errorMessage)
     toast.error(errorMessage)
    }
  }
};

export const getSubscriptionEmailDetail =(data,navigate)=> async (dispatch) => {
  try {
    const response = await axios.post(`${baseUrl}/auth/subscribe-email`, {
      email: data?.email
    });
    console.log("Log After submit subscriptions___",response);
    
    if(response?.status===201){
     navigate("/");
     toast.success()
      return response?.data;
    }
  } catch (error) {
    console.error("Error:",error);
    if(error?.response?.data){
     const errorMessage=error.response.data.message
     console.log("Registration error:",errorMessage)
     toast.error(errorMessage)
    }
  }
};
export const registerCenter =(data)=> async (dispatch) => {
  try {
    const response = await axios.post(`${baseUrl}/auth/register`, {
      centerName:data?.centerName,
      contactPersonName:data?.contactPersonName,
      email: data?.email,
      password: data?.password,
      role:"centerAdmin",
      country:Country.getCountryByCode(data.country)?.name,
      state:State.getStateByCodeAndCountry(data?.state,data?.country)?.name,
      city: data?.city,
      phone: data?.phone,
      address:data?.address,
      zipCode:data?.zipCode,

    });
    if(response?.status===201){
      return response?.data?.user;
    }
  } catch (error) {
    console.error("Error:",error);
    if(error?.response?.data){
     const errorMessage=error.response.data.message
     console.log("Registration error:",errorMessage)
     toast.error(errorMessage)
    }
  }
};

export const login =  (data,navigate)=>async(dispatch) => {
  try {
    const response = await axios.post(`${baseUrl}/auth/login`, {
      email: data?.email,
      password: data?.password,
    });
    if(response?.status===200){
      if(response.data.user.role==="user"){
        dispatch(setUserDetails(response?.data?.user))
       localStorage.setItem("token",JSON.stringify(response?.data?.tokens?.access)) 
       localStorage.setItem("refreshToken", JSON.stringify(response?.data?.tokens?.refresh))
       localStorage.removeItem("email");
       navigate("/")
       return response?.data?.user;
      }else{
        toast.error("User not found ")
      }
    }
  } catch (error) {
    console.error("Error:",error);
    if(error?.response?.data){
     const errorMessage=error.response.data.message
     console.log("Login error:",errorMessage)
     toast.error(errorMessage)
    }
  }
};


export const logout =  ({navigate})=>async(dispatch) => {
  try {
    const refresh= JSON.parse(localStorage.getItem("refreshToken"))
    const refreshToken=refresh?.token;
    const response = await axios.post(`${baseUrl}/auth/logout`, {
      refreshToken:refreshToken
    });
    if(response?.status===204){
      dispatch(setUserDetails())
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken")
      navigate('/login')

    }
    return response;
  } catch (error) {
    console.error("Error:",error);
    if(error?.response?.data){
     const errorMessage=error.response.data.message
     console.log("logut error:",errorMessage)
    }
  }
};

export const forgotPasswordapi =  (data)=>async(dispatch) => {
  try {
    const response = await axios.post(`${baseUrl}/auth/forgot-password`,
    {
      email:data.email
    });
    if(response?.status===200){
      toast.info("Please check your email to reset password")

    }
    return response;
  } catch (error) {
    console.error("Error:",error);
    if(error?.response?.data){
     const errorMessage=error.response.data.message
     toast.error(errorMessage)
     console.log("forgot password error:",errorMessage)
    }
  }
};
export const resetPasswordapi =  (data,resetPasswordToken,navigate)=>async(dispatch) => {
  try {
    const response = await axios.post(`${baseUrl}/auth/reset-password?token=${resetPasswordToken}`,
    {
      password:data.newPassword
    });
    if(response?.status===200){
      toast.success(response?.data?.message)
      navigate("/login")

    }
    return response;
  } catch (error) {
    console.error("Error:",error);
    if(error?.response?.data){
     const errorMessage=error.response.data.message
     toast.error(errorMessage)
     console.log("resetpassword error:",errorMessage)
    }
  }
};


 export const refreshToken =  ()=>async(dispatch) => {
  try {
    const refresh= JSON.parse(localStorage.getItem("refreshToken"))
    const refreshToken=refresh?.token;
    const response = await axios.post(`${baseUrl}/auth/refresh-tokens`, {
      refreshToken:refreshToken
    });
    if(response?.status===200||response?.status===201){
      localStorage.setItem("token",JSON.stringify(response?.data?.access)) 
      localStorage.setItem("refreshToken", JSON.stringify(response?.data?.refresh))
      dispatch(getUserDetails())
    }
    return response;
  } catch (error) {
    console.error("Error:",error);
    if(error?.response?.data){
     const errorMessage=error.response.data.message
     console.log("logut error:",errorMessage)
    }
  }
}; 

export const bookAppoinment =  (data)=>async(dispatch) => {
  try {
    const response = await axios.post(`${baseUrl}/auth/book-appointment`, 
      {data}
    );
    if(response?.status===200||response?.status===201){
      toast.success("Booking request sent successfully")
   
    }
    return response;
  } catch (error) {
    console.error("Error:",error);
    if(error?.response?.data){
     const errorMessage=error.response.data.message
     console.log("logut error:",errorMessage)
    }
  }
}; 
export const sendMessage =  (data)=>async(dispatch) => {
  try {
    const response = await axios.post(`${baseUrl}/auth/contact-us`, 
      {data}
    );
    if(response?.status===200||response?.status===201){
      toast.success("Message sent successfully")
   
    }
    return response;
  } catch (error) {
    console.error("Error:",error);
    if(error?.response?.data){
     const errorMessage=error.response.data.message
     console.log("logut error:",errorMessage)
    }
  }
}; 