import React, { useEffect, useState } from "react";
import style from "./home.module.css";
import VideoPlayer from "../../modals/videoPlayer/VideoPlayer";
import { Row, Col } from "react-bootstrap";

import CenterList from "./CenterList";
import { Link } from "react-router-dom";
import { getAllCenters } from "../../apis/CenterApis";
import { useDispatch } from "react-redux";
import useGetUser from "../../customHook/useGetUser";

import Action from "./Action";
import CardPage from "./Cardpage";
import Mission from "./Mission";

import Movement from "./Movement";
import Newsletter from "./newsletter";
import Neighbour from "./neighbour";
import { Helmet } from "react-helmet-async";
const Home = () => {
  // const dummylist = centerList;
  useGetUser();
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const [centerList, setCenterList] = useState();
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  useEffect(() => {
    dispatch(getAllCenters({ page: 1, limit: 6 })).then((res) => {
      if (res?.centersList) {
        setCenterList(res?.centersList);
      }
    });
  }, []);
  // const handleClick = () => {
  //   // navigate("/search-center")
  //   navigate("/aboutus/");
  // };

  // const handlePlay = (url) => {
  //   setVideoUrl(url);
  //   setShowVideoPlayer(true);
  // };
  return (
    <>
      <Helmet>
        <title>Home - I Stand With The Forgotten Women</title>
        {/* <meta
          name="title"
          content="Support Alternatives to Abortion: Empowering Women with Choices"
        /> */}
        <meta
          name="description"
          content="Explore compassionate alternatives to abortion, empowering women with choices that safeguard life and mental well-being. Support matters."
        />
        <meta
          name="keywords"
          content="Abortion alternatives,Conscience choice,Forgotten women,Psychological consequences,Unplanned pregnancies,Pregnancy support,Abortion regret,Community outreach,Advocacy and policy,Volunteer support"/>
      </Helmet>
      {/* <div className={style.dashboard_main}>
        <div className={style.dashboard_row}>
          <Row className="m-0 p-0">
            <Col md={6} className="m-auto">
              <div className="mt-3">
                <div className={`${style.bannerHeading}`}>
                  Welcome to the I Stand With The Forgotten Women
                </div>
                <div className="pt-4" style={{ fontSize: "20px" }}>
                  Empowering women facing unplanned pregnancies. Join us in
                  rewriting stories of hope. Explore, donate, and make a
                  difference today.
                </div>
                <div className="pt-4">
                  <button className={`greenbtn`} type="button" onClick={handleClick}>
                    Choose a center to support
                  </button>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="my-5">
                <img
                  className="w-100 mt-5 mb-5"
                  src="/dashboard/banner.svg"
                  alt="banner"
                />
              </div>
              <div className="text-center mb-5">
               
                <button
                  className={`bluebtn mb-5 ${style.yelbtn}`}
                  type="button"
                  onClick={handleClick} 
                >
                  Donate Now
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </div> */}
      <div className={`${style.videoContainer}`}>
        <video
          className="w-100"
          src="/Header.mp4"
          alt="banner"
          autoPlay
          muted
          loop
        />
        <div className={`${style.overlayContent} container `}>
          <div className={style.contentvideo}>
            <h1>
              Providing alternatives for those whose conscience dictates a
              different choice
            </h1>
            <h2>
              Their Lives Matter, <span>too!</span>
            </h2>

            <p className={style.contentPara}>
              Abortion rights are our country’s most divisive societal issue
              since the abolitionists took up the cause to abolish slavery.
              President Lincoln understood then what we must understand now. Our
              nation will be unable to uphold its preeminent position among the
              global powers if we continue to hand this longstanding culture war
              off to future generations to fight. Whether you’re pro-life or
              pro-choice, we owe it to them to seek common ground. The common
              ground we all should be able to stand on is women should be
              presented with alternatives to abortion and with the same
              determination that abortion is presented. We should all be able to
              agree that reducing the number of abortions is in the best
              interest of everyone. Not only for the sake of our unborn but for
              the well-being of the mothers who carry them, the fathers who
              conceived them, and for the sake of our humanity..
            </p>
            <div className={style.homesocialicons}>
              <Link
                to="https://www.facebook.com/share/hUuQTv9TUqkxVtBM/?mibextid=LQQJ4d"
                className={style.footer_links}
                target="_blank">
                {" "}
                <img src="/fbsvg.svg" alt="fb"></img>
              </Link>
              <br />
           
              <Link
                to="https://www.instagram.com/istandwithforgottenwomen/"
                className={style.footer_linksinsta}
                target="_blank">
                {" "}
                <img src="/instasvg.svg" alt="insta"></img>
              </Link>
              <br />

              <Link
                to="https://x.com/4gottenwomen"
                className={style.footer_links}
                target="_blank">
                {" "}
                <img src="/twitternew.png" alt="twitter"></img>
              </Link>
              <br />

              <Link
                to="https://www.youtube.com/@istandwiththeforgottenwomen"
                className={style.footer_links}
                target="_blank">
                {" "}
                <img src="/youtube.png" alt="youtube"></img>
              </Link>
              <br />
            </div>
            <div className={`${style.donatebutton} mb-5`}>
              <Link to="/donate-to-us/">
                <button>Stand With Us</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className={style.abort_main}>
        {/* <div className={`text-black ${style.dashboard_row}`}> */}
        <Row style={{ marginRight: "0px" }}>
    
          <Col md={6} className={style.abortimg}>
            <img
              src="/forgotten.png"
              alt="forgotten"
              // playing={false}
              // loop={true}
              // controls={false}
              // muted={true}
              // width="100%"
              // height="100%"
              // playsinline={true}
              style={{
                width: "90%",
                // height: "100%",
                // objectFit: "cover", // Ensures the image covers the container without distortion
                // position: "relative",
                // zIndex: 1,
                // position: "relative",
                // zIndex: 1,
                // background: "black",
              }}
            />
            {/* <img
                  src="/playicon.svg"
                    className="position-absolute top-50 start-50 translate-middle"
                    style={{
                      fontSize: "3rem",
                      color: "white",
                      cursor: "pointer",
                      zIndex: 2,
                     
                    }}
                    // onClick={() =>
                    //   handlePlay(
                    //     "/video.png"
                    //   )
                    // }
                  /> */}

            {/* <video className="mt-4" controls>
                    <source
                      src="https://www.pexels.com/video/light-field-summer-sun-27270701/"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video> */}
            {/* <img
                  className="mt-4"
                  src="/dashboard/abortBanner.svg"
                  alt="banner"
                /> */}

            {/* <div
                  className="pt-3"
                  style={{ fontSize: "20px", color: "#484848" }}
                >
                  Free with donation of any amount
                </div> */}

            <VideoPlayer
              showVideoPlayer={showVideoPlayer}
              setShowVideoPlayer={setShowVideoPlayer}
              videoUrl={videoUrl}
            />
          </Col>
          <Col md={6} className={style.bandata}>
            <div className={style.bancol}>
              <h1 className={style.bannerHeading3}>
                Who Are the Forgotten Women?
              </h1>

              <p className={style.bannerHeading2}>
                Countless women have terminated their pregnancies due to what
                they perceived as insurmountable obstacles that left them
                essentially without a 'choice' and many others are left
                struggling with the psychological consequences. These are the
                forgotten women whose plight has been omitted from the public
                discourse on abortion rights and slipped through the cracks of
                our compassion.
              </p>
              <p className={style.bannerHeading2}>
                These women need and deserve our support.
                <p>
                  <span> Their Lives Matter, too!</span>
                </p>
              </p>
              <div className={style.bannerbutton}>
                <Link to="/donate-to-us/">
                  <button>Stand With Us</button>
                </Link>
              </div>
            </div>
            {/* <div
                  className="pt-4"
                  style={{ fontSize: "20px", color: "#484848" }}
                >
                  Buy your copy now and help bring their stories to light.
                </div> */}
            {/* <div className="py-4">
                  <button className={`yellowbtn  ${style.yelbtn}`} type="button">
                    Buy Now
                  </button>
                </div> */}
          </Col>
        </Row>

      </div>

      <Action />
      <Neighbour></Neighbour>
      <Mission></Mission>
      <CardPage />
      <div className={style.standcontainer}>
        <img className="w-100" src="/still.jpg" alt="banner" />
        <div className={style.standtext}>
          <h1>I Stand With Them</h1>
     
          <a href="/letter/">
            {" "}
            <p>Letter from the Founder</p>{" "}
          </a>
        </div>
      </div>
    

      <CenterList centerList={centerList} showfilter={false} />
   
      <Movement></Movement>
      <Newsletter></Newsletter>


    </>
  );
};

export default Home;
