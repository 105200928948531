import React, { useEffect, useRef, useState } from "react";
import style from "./search.module.css";
import { Row, Col, Form, Button, InputGroup, Dropdown } from "react-bootstrap";

import { getAllCenters } from "../../apis/CenterApis";
import { useDispatch } from "react-redux";
import { searchSuggestion } from "../../apis/CenterApis";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AiOutlinePlayCircle } from "react-icons/ai";

import ReactPlayer from "react-player/lazy";

import { searchCenters } from "../../apis/CenterApis";
import { fileBaseUrl } from "../../utils/api";
import VideoPlayer from "../../modals/videoPlayer/VideoPlayer";
import { Helmet } from "react-helmet-async";
const SearchCenter = () => {
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const location = useLocation();
  const [centerList, setCenterList] = useState();
  const [searchCenter, setSearchCenter] = useState(null);
  const [showfilter, setShowFilter] = useState(null);
  const [debouncedQuery, setDebouncedQuery] = useState(null);
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [suggestionList, setSuggestionList] = useState(null);
  const [selectedSuggest, setSelectedSuggest] = useState(null);
  const[totalcenter,setTotalCenter]=useState(0);

  useEffect(() => {
    if (location?.state) {
      setSearchCenter(location?.state);
      const timerId = setTimeout(() => {
        setSelectedSuggest(location?.state);
        setShowFilter(location?.state);
        setShowSuggestion(false);
      }, 300);
      return () => clearTimeout(timerId);
    }
  }, [location]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowSuggestion(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  function truncateString(str, maxLength) {
    if (str.length <= maxLength) {
      return str;
    } else {
      return str.slice(0, maxLength) + "...";
    }
  }

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedQuery(searchCenter);
    }, 300);

    return () => clearTimeout(timerId);
  }, [searchCenter]);

  useEffect(() => {
    if (debouncedQuery && !selectedSuggest) {
      dispatch(searchSuggestion({ search: debouncedQuery })).then((res) => {
        if (res?.length) {
          setShowSuggestion(true);
          setSuggestionList(res);
        } else {
          setShowSuggestion(false);
        }
      });
    } else {
      setShowSuggestion(false);
    }
  }, [debouncedQuery]);

  const handleSearch = () => {
    setShowFilter(searchCenter);
    setShowSuggestion(false);
    setSelectedSuggest(null);
  };
  const handleChange = (e) => {
    setSelectedSuggest(null);
    setSearchCenter(e.target.value);
  };
  const handleSuggestion = (value) => {
    setSearchCenter(value);
    setSelectedSuggest(value);
    setShowFilter(value);
    setShowSuggestion(false);
  };

  const navigate = useNavigate();

  const [files, setFiles] = useState([]);
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [showNext, setShowNext] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(9);
  const [isdata, setIsData] = useState(false);

  const filesData = (value) => {
    const addfileType = value?.map((file) => {
      const fileExtension = file?.media?.split(".").pop().toLowerCase();
      const imageExtensions = ["jpg", "jpeg", "png", "gif"];
      const videoExtensions = ["mp4", "avi", "mov", "mkv"];
      const youtubePattern = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
      if (imageExtensions.includes(fileExtension)) {
        file.type = "image";
      } else if (videoExtensions.includes(fileExtension)) {
        file.type = "video";
      } 
      else if (youtubePattern.test(file.media)) {
        
        file.type = "youtube";
      }
      else {
        file.type = "unsupported";
      }
      
     
      setIsData(true);
      return file;
    });
    return addfileType;
  };

  const fetchSearchCenter = () => {
    dispatch(
      searchCenters({
        centerName: searchCenter,
        // country: selectedCountry,
        // state: selectedState,
        page: page,
        limit: limit,
      })
    ).then((res) => {
      if (res) {
        setFiles(filesData(res?.centersList));
        setPage(res?.currentPage);
        if (res?.currentPage < res?.totalPages) {
          setShowNext(true);
        } else {
          setShowNext(false);
        }
      }
    });
  };

  useEffect(() => {
    setFiles(filesData(centerList));
  }, [centerList]);

  useEffect(() => {
    if (!searchCenter) {
      setFiles(filesData(centerList));
    }
  }, [searchCenter]);

  useEffect(() => {
    if (showfilter && searchCenter) {
      fetchSearchCenter();
    }
  }, [showfilter]);


  const handleClick = (data) => {
    // navigate("/donation",  { state: data });
    navigate(`/donation/${data.id ? data.id : data._id}`);

    localStorage.setItem("centerId", data.id ? data.id : data._id);
  };
  
  // const handleClick = (data) => {
  //   navigate("/donation", { state: data });
  //   localStorage.setItem("centerId", data.id ? data.id : data._id);
  // };
  const handlePlay = (url) => {
    setVideoUrl(url);
    setShowVideoPlayer(true);
  };

  const handleShowMore = () => {
    dispatch(
      searchCenters({
        centerName: searchCenter,
        // country: selectedCountry,
        // state: selectedState,
        page: page + 1,
        limit: limit,
      })
    ).then((res) => {
      if (res) {
        setFiles(filesData(res?.centersList));
        setPage(res?.currentPage);
        if (res?.currentPage < res?.totalPages) {
          setShowNext(true);
        } else {
          setShowNext(false);
          setPage(0)
        }
      }
    });

  };

  useEffect(() => {
    dispatch(getAllCenters({ page: 1, limit: 20 })).then((res) => {
      if (res?.centersList) {
        setCenterList(res?.centersList);
        setTotalCenter(res?.toatalCenters)
        setPage(1);
      }
    });
  },[] );

  const handleLoadMore = () => {
    console.log("entering handleLoadMore");
    console.log("page", page);
  
    const nextPage = page + 1; // Calculate the next page
    setPage(nextPage); // Update page state
  
    // Make sure page is a valid number
    if (Number.isNaN(nextPage) || typeof nextPage !== 'number') {
      console.error("Invalid page number:", nextPage);
      return; // Exit the function if the page number is invalid
    }
  
    dispatch(
      getAllCenters({
        page: nextPage,
        limit: 20,
      })
    ).then((res) => {
      if (res?.centersList) {
        setCenterList((prev) => [...prev, ...res.centersList]); // Append new centers
        if (res.currentPage < res.totalPages) {
          setShowNext(true);
        } else {
          setShowNext(false);
        }
      }
    });
  };
 
  function truncateText(text, maxLength) {
    if (text.length <= maxLength) {
      return `, ${text}`;
    }
    return `, ${text.substr(0, maxLength) + "..."}`;
  }
  return (
    <>
     <Helmet>
        <title>SearchCenter - I Stand With The Forgotten Women</title>
        <meta name="description" content="Search for pregnancy help centers, maternity homes, and adoption agencies in your area. Connect with resources and support for every stage of motherhood."/>
        <meta name="keywords" content="Pregnancy help center,Maternity home,Adoption agency,Search center,Preconception counseling,Prenatal care,Supportive organization,Empowering women,Unplanned pregnancies,Donation"/>
      </Helmet>
      <div className="mt-5">
        <div className={style.centerHeading}>
          Whether it's a pregnancy help center, maternity home, or an adoption
          agency, they're all here.
        </div>
        <div className="mt-4 mb-5">
          <Row className="m-0 p-0">
            <Col
              lg={{ span: 6, offset: 3 }}
              md={{ span: 8, offset: 2 }}
              xs={12}>
              <InputGroup>
                <Form.Control
                  className={style.serchInputsec}
                  placeholder="Search"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  value={searchCenter}
                  onChange={handleChange}
                />

                <Button
                  className={style.serchInputbtn}
                  variant="primary"
                  id="button-addon2"
                  onClick={handleSearch}>
                  Search
                </Button>
              </InputGroup>
              <div ref={dropdownRef} className="mt-2">
                <Dropdown.Menu
                  className={style.suggestion}
                  show={showSuggestion}>
                  {suggestionList?.map((value, index) => (
                    <Dropdown.Item
                      key={index}
                      onClick={() => {
                        handleSuggestion(value);
                      }}>
                      {value}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </div>

            </Col>
          </Row>
        </div>
        <div>
          <div className={style.maincentercontainer} style={{}}>
            <div className={style.dashboard_row}>
              <div className={style.centerBox}>
                <div>
                  {files?.length === 0 && (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: "25vh" }}>
                      <h3>No Data Found</h3>
                    </div>
                  )}
                </div>

                <div className="container">
                  <Row className="m-0 p-0">
                    {files ?.filter((data) => data.description)
                      .map((data, index) => (
                        <Col className="p-3 " key={index} lg={4} md={6} sm={6}>
                          <div className={`${style.centerCard}`}>
                            <div className="position-relative image-container">
                              {data?.type === "unsupported" && (
                                <img
                                  className="w-100 "
                                  src={`/defaultCenter.png`}
                                  alt="center"
                                />
                              )}
                              {data?.type === "image" && (
                                <img
                                  className="w-100 "
                                  src={`${fileBaseUrl}${data.media}`}
                                  alt="center"
                                />
                              )}
                              {data?.type === "video" && (
                                <ReactPlayer
                                  url={`${fileBaseUrl}${data?.media}`}
                                  playing={false}
                                  loop={true}
                                  controls={false}
                                  muted={true}
                                  width="100%"
                                  height="100%"
                                  playsinline={true}
                                  style={{
                                    paddingTop: "15px",
                                    paddingBottom: "9px",
                                  }}
                                />
                              )}

                              {data?.type === "youtube" && (
                                <ReactPlayer
                                  url={data.media} // Assuming data.media contains the YouTube URL
                                  playing={false}
                                  loop={true}
                                  controls={true} // You might want controls for YouTube
                                  muted={false} // You can decide if you want it muted
                                  width="100%"
                                  height="100%"
                                  playsinline={true}
                                  className={style.fixedvideo}
                                />
                              )}
                              <div
                                class="overlay"
                                onClick={() =>
                                  data?.type === "video" &&
                                  handlePlay(data?.media)
                                }></div>
                              {data?.type === "video" && (
                                <AiOutlinePlayCircle
                                  className="position-absolute top-50 start-50 translate-middle overlayIcon"
                                  style={{ fontSize: "3rem", color: "white" }}
                                  onClick={() => handlePlay(data?.media)}
                                />
                              )}
                            </div>
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => handleClick(data)}>
                              <div
                                className="my-2"
                                style={{
                                  fontSize: "17px",
                                  textTransform: "capitalize",
                                  fontWeight: "bold",
                                  lineHeight: "30px",
                                }}>
                                {data.centerName}
                              </div>
                              <div
                                style={{
                                  color: "#191919",
                                  minHeight: "120px",
                                  maxHeight: "120px",
                                  WebkitLineClamp: 4,
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  fontSize: "17px",
                                  lineHeight: "30px",
                                  fontWeight: "400",
                                  // whiteSpace:"nowrap"
                                }}>
                               {truncateString(data.description, 120)}
                              </div>
                            </div>
                            <div
                              className=" d-flex justify-content-between align-items-center "
                              style={{ marginTop: "28px" ,}}>
                              <div   style={{ fontSize:"12px", color:"gray"}}>
                                {" "}
                                {data.country === "United States"
                                  ? "US"
                                  : data.country}
                                {data.state ? truncateText(data.state, 12) : ""}{" "}
                              </div>
                              <Button
                                variant="primary"
                                size="sm"
                                className="authBlubtn px-3 rounded-0"
                                onClick={() => handleClick(data)}>
                                Donate Now{" "}
                              </Button>
                            </div>
                          </div>
                        </Col>
                      ))}
                  </Row>
                </div>
                {showNext && (
                  <div className="text-center">
                    <Button variant="light" onClick={handleShowMore}>
                      Show More
                    </Button>
                  </div>
                )}
                {location?.pathname !== "/search-center" && (
                  <div className="text-center mt-5">
                    <p
                      onClick={() => navigate("/search-center")}
                      className={style.centerbrowse}>
                      {" "}
                      Browse all
                    </p>
                    {/* <Button
                  className={`${style.browsebutton}`}
                  onClick={() => navigate("/search-center")}
                >
                  Browse All
                </Button> */}
                  </div>
                )}
                  {centerList?.length<totalcenter && (
                   
                  <div className="text-center">
                    <a onClick={handleLoadMore} style={{cursor:"pointer"}}>
                    Load More
                    </a>
                    <br></br>
                    {/* <Button variant="light" onClick={handleLoadMore}>
                      Load More
                    </Button> */}
                  </div>
                )}
              </div>
            </div>
          </div>
          <VideoPlayer
            showVideoPlayer={showVideoPlayer}
            setShowVideoPlayer={setShowVideoPlayer}
            videoUrl={videoUrl}
            isdata={isdata}
          />
        </div>
        {/* <div className="pt-4">
          <CenterList
            centerList={centerList}
            searchCenter={searchCenter}
            showfilter={showfilter}
          />
        </div> */}
      </div>
    </>
  );
};

export default SearchCenter;
